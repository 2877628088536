<template>
  <v-container
    fluid
    class="px-0 py-0"
  >
    <v-row>
      <v-col
        cols="12"
        class="py-0"
      >
        <import-summary
          @stepTourSummaryReleased="$emit('stepTourSummaryReleased')"
          :contadores="contadores"
          :arquivos="arquivos"
        />
      </v-col>
    </v-row>
    <v-row v-if="tabItens.length > 5">
      <v-col
        cols="12"
        class="pb-0 mb-n3"
      >
        <v-text-field
          v-model="filtroEntidades"
          append-icon="mdi-magnify"
          label="Buscar Entidades"
          hide-details
          single-line
        />
      </v-col>
    </v-row>
    <v-row v-if="filteredItens.length">
      <v-col
        cols="5"
        sm="3"
        class="pt-2 pb-0"
        id="menu-esquerdo-entidades-ib"
      >
        <v-card
          class="mt-4 mb-0 pb-0"
          style="height: 93% !important"
          elevation="2"
        >
          <v-list
            flat
            class="overflow-y-auto pb-0"
            max-height="120vh"
          >
            <v-list-item-group
              v-model="selectedItem"
              color="primary"
              mandatory
            >
              <v-list-item
                v-for="(item, i) in filteredItens"
                :key="i"
                style="min-height: 38px !important; max-height: 38px !important"
              >
                <v-list-item-icon class="my-2">
                  <v-icon>{{
                    selectedItem == i ? 'mdi-folder-open' : 'mdi-folder'
                  }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="`${item} (${arquivos[item].length})`"
                  />
                </v-list-item-content>
              </v-list-item>
              <v-divider />
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col
        cols="7"
        sm="9"
        class="pb-0"
        v-if="selectedItemListGroup"
      >
        <table-import-entity-files
          :entidade="selectedItemListGroup"
          :arquivos="arquivos[selectedItemListGroup.toUpperCase()]"
          :contadores="contadores[selectedItemListGroup.toLowerCase()]"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {
    ImportSummary: () =>
      import('@/components/geracao-bdgd/importacoes-bdgd/ImportSummary'),
    TableImportEntityFiles: () =>
      import(
        '@/components/geracao-bdgd/importacoes-bdgd/TableImportEntityFiles'
      )
  },

  props: {
    entidadesBdgd: {
      type: String,
      required: true
    },
    arquivos: {
      type: Object,
      required: true
    },
    contadores: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    filtroEntidades: '',
    tabItens: [],
    selectedItem: 0
  }),

  mounted() {
    this.getTabItens();
  },

  methods: {
    getTabItens() {
      this.tabItens = this.$props.entidadesBdgd.split(',');
    }
  },

  computed: {
    filteredItens() {
      return this.tabItens.filter((item) =>
        item.toLowerCase().includes(this.filtroEntidades.toLowerCase())
      );
    },

    selectedItemListGroup() {
      return this.filteredItens.length
        ? this.filteredItens[this.selectedItem]
        : '';
    }
  }
};
</script>
