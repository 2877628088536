import APIService from './APIService';

export default {
  getImportacoes() {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get('importacoes/bdgd')
        .then((response) => {
          const { data: importacoes } = response.data;
          resolve(importacoes);
        })
        .catch((error) => reject(error));
    });
  },
  save(data) {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .post('importacoes/bdgd/salvar', JSON.stringify(data))
        .then(() => resolve(true))
        .catch(() => reject(false));
    });
  },
  getAllowedFiles() {
    return APIService.apiCall().get('importacoes/bdgd/arquivos-permitidos');
  },
  getEntidades(props) {
    const query = props.modulo10_antigo ? '?modulo10_antigo=true' : '';
    return APIService.apiCall().get(`importacoes/bdgd/entidades-bdgd${query}`);
  },
  getLogsImportacao(id) {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(`importacoes/bdgd/logs/${id}`)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  baixarLogsValidacoes(id) {
    return APIService.apiCall().get(`importacoes/bdgd/logs-validacoes/${id}`, {
      responseType: 'blob',
      ...{
        timeout: 60 * 60 * 1000
      }
    });
  },
  baixarLogsTecnicos(id) {
    return APIService.apiCall().get(`importacoes/bdgd/logs-tecnicos/${id}`, {
      responseType: 'blob',
      ...{
        timeout: 60 * 60 * 1000
      }
    });
  },
  baixarArquivo(id) {
    return APIService.apiCall().get(`importacoes/bdgd/download-arquivo/${id}`, {
      responseType: 'blob',
      ...{
        timeout: 60 * 60 * 1000
      }
    });
  }
};
